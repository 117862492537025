import _ from 'lodash'
import { ResizeObserver } from '@juggle/resize-observer'
import Utils from '../../lib/utils'
import * as Analytics from '../../lib/analytics'
import { buildPropertyGroups } from '../../lib/helpers/property-groups.helper'
import * as Auth from '../../lib/auth'
import { purifyURL } from '../../lib/dom/html'
import * as ConfigExperimentsAPI from '../../lib/config-experiments'
import { MetricsFunnelNodeViewDirectiveInstance, MetricsFunnelNodeGridViewModelFactory } from './metrics-grid.directive'
import { MetricsFunnelNodeServiceFactory } from './metrics-funnel'
import { SidebarModel } from '../../components/sidebar'
import { ActionsPanelButtonItem, ActionsPanelSimpleItem, ActionsPanelModel } from '../../components/actions-panel'
import { MetricsPageTabsViewModel } from './metrics-tabs-view-model'

module = angular.module '42.controllers.metrics', [
    '42.modules.libs.utils'
]

module.directive('metricsFunnelNodeView', MetricsFunnelNodeViewDirectiveInstance())
module.factory('MetricsFunnelNodeGridViewModel', MetricsFunnelNodeGridViewModelFactory())
module.service('MetricsFunnelNodeService', MetricsFunnelNodeServiceFactory())


module.config ($routeProvider, ROUTES, CONFIG) ->
    routeId = 'metrics'
    route = _.extend {}, ROUTES[routeId], _.pick(CONFIG.routes?[routeId], 'label', 'url')
    $routeProvider.when(route.url, route)


module.service 'MetricsHierarchy', ($q, Hierarchy, HourProperty) ->
    fetch: -> $q.all([
        Hierarchy.fetch().then(({groupBy}) -> groupBy)
        HourProperty.fetch()
    ]).then ([hierarchy, hourProperty]) ->
        hierarchy.push(hourProperty) if hourProperty
        return hierarchy


module.controller 'MetricsController', ($q, $rootScope, $scope, CONFIG, MetricsHierarchy, MetricsKPIsService) ->

    refresh = -> $q.all([
        MetricsHierarchy.fetch(),
        MetricsKPIsService.fetch($rootScope.query),
        Auth.getOrganization(),
        Auth.getUser(),
        ConfigExperimentsAPI.fetch(),
        do ->
            storage = MetricsPageTabsViewModel.Storage()
            state = await storage.get()
            return [storage, state]
    ]).then ([properties, metrics, organization, user, experiments, [storage, state]]) ->
        if experiments.sidebar
            properties = buildPropertyGroups(properties).reduce(((acc, group) ->
                return acc.concat(group.properties)
            ), [])

        tabs = MetricsPageTabsViewModel.deserialize(storage, {properties, metrics, state})
        smartGroups = if experiments.segmentsInMetricsPage then $rootScope.smartGroupsService else null
        $scope.model = {
            sort: CONFIG.defaults?.metrics?.sortBy or 'net_sales',
            tabs,
            organization,
            smartGroups,
            storage,
            experiments,
            user
        }

    $rootScope.$on '$destroy', \
    $rootScope.$watch 'initialized', (initialized) ->
        return if not initialized
        return refresh()


module.service 'MetricsGridCellClasses', ->
    percent = (value) ->
        return 'percent-negative' if value < 0
        return 'percent-positive' if value > 0
        return null
    'percent': (cell) ->
        return percent(cell.value)
    'percent-inverted': (cell) ->
        return percent(cell.value * -1)


module.service 'MetricsGridCellRenderers', ($filter) ->
    blank: ->
        return '<span class="cell-blank-value">—</span>'
    link: (row) ->
        anchorElement = document.createElement('a')
        anchorElement.href = purifyURL(row.value)
        anchorElement.setAttribute("target", "_blank")
        anchorElement.textContent = row.value
        return anchorElement.outerHTML
    metric: (row) ->
        return row.value if not row.colDef.cellFilter
        [filter, args...] = row.colDef.cellFilter.split(':')
        return $filter(filter)(row.value, args...)
    image: (row) ->
        container = document.createElement('div')
        container.classList.add('blank') if not row.item_image
        if row.item_image
            imgElement = document.createElement('img')
            imgElement.setAttribute("onerror", "this.parentElement.classList.add('blank')")
            imgElement.setAttribute("src", purifyURL(row.item_image))
            container.appendChild(imgElement)
        return container.outerHTML


module.factory 'MetricsGridDefaultCellRenderer', (MetricsGridCellRenderers) -> (row) ->
    return MetricsGridCellRenderers.blank(row) if _.isNil(row.value)
    return MetricsGridCellRenderers.link(row) if row.colDef.field is 'item_image'
    return MetricsGridCellRenderers.metric(row)


# This is a super hack to get the metrics supported by the dataset
module.service 'MetricsKPIsService', ($q, $rootScope, CONFIG, QueryMetrics, MetricsGridCellClasses) -> fetch: (query) ->
    query = Utils.copy(query or {})
    query.filters = {transactions:query.filters.transactions}
    query.options = {property:"stores.aggregate"}

    $q.all([
        QueryMetrics.fetch(),
        Auth.getOrganization()
    ]).then ([metrics, org]) ->

        isPercentageParentMetric = (metric) ->
            /^(growth_)?percentage_parent_/.test(metric.field)

        isPercentageMetric = (metric) ->
            /^(growth_)?percentage_/.test(metric.field) and not isPercentageParentMetric(metric)

        # We don't want to show the percent parent metrics on metrics page because there's only one level.
        if not Auth.isAllsaintsOrganizationId(org)
            metrics = metrics.filter (metric) ->
                not isPercentageParentMetric(metric) or (not isPercentageMetric(metric) and (
                    metric.field.includes('sessions')
                ))

        metrics = metrics.map (x) ->
            x._cellClass = x.cellClass
            x.cellClass = MetricsGridCellClasses[x.cellClass] or x.cellClass
            return x

        filterMetric = (kpis) ->
            return kpis.filter (kpi) ->
                return false if not ($rootScope.flags.showBudget or $rootScope.flags.showBudgets) and kpi.includes('budget')
                return true

        getMetricsKPIs = ->
            availableKpis = Utils.copy(metrics)
            enabledKpis = do ->
                userEnabledKpis = $rootScope.accessControl?.kpis
                orgEnabledKpis = CONFIG.views?.metrics?.kpis
                return if not orgEnabledKpis
                return _.compact(_.uniq(_.concat(orgEnabledKpis, userEnabledKpis)))
            return availableKpis if not enabledKpis
            enabledKpis = filterMetric(enabledKpis) if ['allsaints_dev', 'allsaints-new', 'allsaints'].includes(org)
            availableKpisIndex = _.keyBy availableKpis, (x) -> x.field
            return _.compact enabledKpis?.map (kpi) -> availableKpisIndex[kpi]

        allMetricsFactory = ->
            kpis = getMetricsKPIs()
            kpis.copy = -> allMetricsFactory()
            return kpis

        return allMetricsFactory()



module.directive 'metricsFunnelBreadcrumbHeader', ['$rootScope',
###* @returns {angular.IDirective<angular.IScope & {
    collapse          : boolean
    isEmpty           : boolean
    isSegmentsEnabled : boolean
    onReset           : () => void
}>} scope ###
($rootScope) ->
    restrict: 'E'
    scope:
        isEmpty: '='
        onReset: '&'
    replace: true,
    template: \
    """
    <header class="metrics-funnel-breadcrumb-header">
        <div class="info">
            <h1>Selected Filters</h1>
            <button class="reset" ng-if="!isEmpty" ng-click="onReset()">reset</button>
        </div>
        <div ng-if="!collapse && isEmpty && isSegmentsEnabled" class="help-text-container">
            <p class="help-text">
                <i class="icon-help-circled"></i>
                select a
                <span class="pellet">group by</span>
                to see values for that attribute, click on a row's
                <span class="cell-value no-border">
                    <button>value</button>
                </span>
                to drill down
            </p>
        </div>
    </header>
    """
    link: (scope, element) ->

        scope.$on '$destroy', $rootScope.$watch 'Experiments.segmentsInMetricsPage', (flag) ->
            scope.isSegmentsEnabled = flag

        HELP_TEXT_CONTAINER_WIDTH = 568
        scope.collapse = false

        shouldCollapse = ->
            elementWidth = element.width() ? 0
            infoContainer = element.find('.info')
            infoContainerWidth = infoContainer?.get(0)?.offsetWidth ? 0
            return (elementWidth - infoContainerWidth - 15) <= HELP_TEXT_CONTAINER_WIDTH

        updateCollapse = ->
            scope.collapse = shouldCollapse()

        resizeObserver = do ->
            observer = new ResizeObserver () -> updateCollapse()
            observer.observe(element[0]) if element[0]
            return observer

        scope.$watch('isEmpty', -> updateCollapse())
        scope.$on('$destroy', -> resizeObserver.disconnect())
]


module.directive 'metricsFunnelBreadcrumb', ['$rootScope', 'promiseTracker',
###* @returns {angular.IDirective<angular.IScope & {[x: string]: any}>} scope ###
($rootScope, promiseTracker) ->
    restrict: 'E'
    scope:
        user          : '='
        funnel        : '=model'
        export        : '='
        segment       : '='
        panelToggle   : '='
        sidebarToggle : '='
        options       : '='
        onReset       : '&'
        refresh       : '='
        exportPromiseTracker: '='
        experiments   : '='
    replace: true
    template: \
    """
    <article class="metrics-funnel-breadcrumb">
        <section class="funnel-state">
            <metrics-funnel-breadcrumb-header is-empty="isEmpty" on-reset="onReset()"></metrics-funnel-breadcrumb-header>
            <main class="ui-pellets">
                <ul>
                    <div ng-repeat="x in view.nodes" class="selection-pebble-item">
                        <selection-pebble model="x"></selection-pebble>
                    </div>
                </ul>
                <p class="help-text old" ng-if="!collapse && isEmpty && !isSegmentsEnabled && !experiments.sidebar">
                    <i class="icon-help-circled"></i>
                    click on a
                    <span class="ui-pellet"><span>property</span></span>
                    to change how the rows are rolled up, click on a row's
                    <span class="cell-value no-border">value</span>
                    to drill down
                </p>
                <p class="help-text-sidebar old" ng-if="!collapse && isEmpty && !isSegmentsEnabled && experiments.sidebar">
                    <i class="icon-help-circled"></i>
                    Click on a row's
                    <span class="cell-value no-border">value</span>
                    to drill down
                </p>
            </main>
        </section>

        <div class="collapsed-header-actions">
            <button-export on-click="onExportClick()" text="exportButtonText"></button-export>
            <div class="toggle-header-button">
                <div class="show-header-button" ng-click="panelToggle.open($event)">
                    <span>Show Panel</span>
                    <i class="icon-down-open"></i>
                </div>
            </div>
        </div>

        <div class="actions-selection-panel">
            <properties-items ng-if="pebblesGroupByModel" model="pebblesGroupByModel"></properties-items>
            <div class="right-side" ng-if="isSidebarDisabled && metricsTreeModel">
                <div class="top-part">
                    <ui-metric-selector-tree model="metricsTreeModel"> </ui-metric-selector-tree>
                </div>
                <div class="bottom-part" ng-if="exportModel && togglePanelModel">
                    <actions-panel-secondary-button-item
                        ng-if="exportModel"
                        item="exportModel">
                    </actions-panel-secondary-button-item>
                    <actions-panel-secondary-simple-item
                        ng-if="togglePanelModel"
                        item="togglePanelModel">
                    </actions-panel-secondary-simple-item>
                </div>
            </div>
        </div>
    </article>
    """
    link: (scope) ->
        scope.exportButtonText = 'export'
        scope.segmentItem = { value: null, property: { label: 'segment' }, segment: true }

        scope.getPropertiesLabel = (properties) ->
            return properties.map((p) -> p.label).join(', ')

        scope.onExportClick = ->
            Analytics.track(Analytics.EVENTS.USER_EXPORTED_PAGE_METRICS)
            return scope.export()

        scope.removeNode = (node) ->
            scope.funnel.removeNode(node)
            scope.refresh()

        ## REMOVE CODE WHEN SEGMENTS TOGGLE IS REMOVED `enableSegmentsInMetricsPage`
        do ->
            HELP_TEXT_CONTAINER_WIDTH = 568
            helpTextObserver = null
            verifyHelpTextSize = null
            unsubSegmentChange = null
            unsubSegments = \
            $rootScope.$watch 'Experiments.segmentsInMetricsPage', (segmentsInMetricsPage) ->
                scope.isSegmentsEnabled = segmentsInMetricsPage
                unsubSegmentChange?()

                if segmentsInMetricsPage
                    unsubSegmentChange = scope.$watch 'segment', (segment) ->
                        if segment
                            if scope.segmentItem.value isnt segment
                                scope.segmentItem.value = segment ? null
                                scope.pellets = [scope.segmentItem].concat(scope.funnel?.nodes or [])
                        else
                            if scope.pellets?.length > 0 and scope.pellets[0].segment
                                scope.pellets = scope.funnel?.nodes or []
                    return

                verifyHelpTextSize ?= do ->
                    fn = ->
                        return if $rootScope.Experiments?.segmentsInMetricsPage
                        infoContainer = document.getElementsByClassName('funnel-state')?[0]
                        if infoContainer
                            infoContainerWidth = infoContainer.offsetWidth or 0
                            scope.collapse = (infoContainerWidth - 15) <= HELP_TEXT_CONTAINER_WIDTH
                    scope.$watch('isEmpty', -> fn())
                    return fn
                helpTextObserver ?= do ->
                    observer = new ResizeObserver () -> verifyHelpTextSize()
                    observer.observe(document.getElementsByClassName('view-metrics')[0])
                    return observer
                return

            scope.collapse = false
            scope.$on '$destroy', ->
                unsubSegments()
                helpTextObserver?.disconnect()

        exportPromiseTracker = promiseTracker()

        buildTogglePanelModelAndExport = ->
            scope.togglePanelModel =
                label: 'Hide Panel'
                icon: {type: 'icon-up-open'}
                onClick: ($event) ->
                    scope.panelToggle.close($event)

            scope.exportModel =
                label: 'Export',
                icon: {type: 'icon-export'}
                isLoading: () ->
                    exportPromiseTracker?.active()
                onClick: () ->
                    exportPromiseTracker.cancel()
                    exportPromiseTracker.addPromise(scope.onExportClick())

        scope.view = {
            nodes: []
        }

        scope.$watch 'funnel', (->
            return if not scope.funnel
            nodes = do ->
                return scope.funnel.nodes if Array.isArray(scope.funnel.nodes)
                return []
            selectedIndex = null
            scope.view.nodes = nodes.map (node, index) ->
                selected = scope.funnel.node is node
                selectedIndex = index if selected

                return
                    id: node.id
                    label: scope.getPropertiesLabel(node.property)
                    value: node.valueLabel
                    draggable: false
                    selectable: true
                    translucid: selectedIndex isnt null and index > selectedIndex
                    selected: scope.funnel.node is node
                    onClick: ->
                        scope.funnel.selectNode(node)
                        return
                    onIconClick: ->
                        scope.removeNode(node)
                        return
                    icon: do ->
                        return undefined if scope.funnel.node is node
                        return 'icon-cancel-circled'
                    color: 'neutral'

            return if not scope.funnel?.metrics

            {sidebar, metricsCategorization} = scope.options
            scope.isSidebarDisabled = not sidebar

            if scope.isSidebarDisabled
                buildTogglePanelModelAndExport()
                scope.pebblesGroupByModel = null
                scope.metricsTreeModel = null
                scope.actionsPanelModel = null
            else
                scope.togglePanelModel = null
                scope.exportModel = null

            scope.pebblesGroupByModel = do ->
                return null if not scope.isSidebarDisabled
                return
                    label: 'Rows By',
                    available: scope.funnel.node.properties
                    selected: scope.funnel.node.property
                    options:
                        multipleSelection: true
                    onClick: (property) -> scope.funnel.setProperty(property)

            scope.metricsTreeModel = do ->
                return null if not scope.isSidebarDisabled
                { selected: selectedMetrics, available: availableMetrics } = _.cloneDeep(scope.funnel.metrics)
                return
                    selected: selectedMetrics.map((m) -> m.field)
                    available: availableMetrics
                    options: { addAllMetrics: false, hideCategories: not metricsCategorization }
                    onChange: (metrics) ->
                        newMetrics = metrics.map((x) -> x.field)
                        scope.funnel.selectMetrics(newMetrics)

            scope.actionsPanelModel = do ->
                return if scope.isSidebarDisabled
                actionsPanelModel = []
                actionsPanelModel.push(new ActionsPanelButtonItem({
                    label: 'Rows By',
                    selected: scope.funnel.node.property.map((p) -> p.label).join(', ')
                    cssClass: 'sidebar-toggle'
                    icon: {type: 'icon-down-open'}
                    isActive: ->
                        Boolean(scope.sidebarToggle?.isActive) and scope.sidebarToggle.tab is 'properties'
                    onClick: ($event) ->
                        scope.sidebarToggle.toggle($event, 'properties')
                }))

                actionsPanelModel.push(new ActionsPanelSimpleItem({
                    label: 'Edit Metrics',
                    icon: {type: 'icon-flow-cascade'}
                    cssClass: 'sidebar-toggle'
                    isActive: ->
                        scope.sidebarToggle?.isActive and scope.sidebarToggle.tab is 'metrics'
                    onClick: ($event) ->
                        scope.sidebarToggle.toggle($event, 'metrics')
                }))

                actionsPanelModel.push(new ActionsPanelButtonItem({
                    secondary: true,
                    label: 'Export',
                    icon: {type: 'icon-export'}
                    isLoading: () ->
                        exportPromiseTracker?.active()
                    onClick: () ->
                        exportPromiseTracker.cancel()
                        exportPromiseTracker.addPromise(scope.onExportClick())
                }))

                return new ActionsPanelModel({items:actionsPanelModel})
        ), true

        scope.$watch 'funnel.metrics', ((metrics, prev) ->
            if not metrics
                scope.metricSelectModel = null
                return
            if scope.metricsSelectModel and _.isEqual(metrics.selected, prev?.selected)
                return

            scope.metricSelectModel =
                available : _.cloneDeep(metrics.available)
                selected  : metrics.selected.map((x) -> x.field)
                options   : {addAllMetrics: false, hideCategories: not Boolean($rootScope.Experiments.metricsCategorization)}
                onChange  : (metrics) -> scope.funnel.metrics.select(metrics)
        ), true

        scope.$watch 'funnel.nodes', (nodes) ->
            scope.isEmpty = _.isNil(nodes[0]?.value)
]


module.directive 'metricsView', ($timeout, promiseTracker) ->
    restrict: 'E'
    scope:
        model: '='
    replace: true
    template: \
    """
    <section class="view view-metrics" drag-and-drop-zone>
        <div class="loadable" ng-class="{loading:!model}"></div>

        <tabs-with-menu
            tabs       = "model.tabs.available"
            added      = "model.tabs.addTab"
            removed    = "removeTab"
            selected   = "model.tabs.selected"
            dragged    = "model.tabs.reorderTabs"
            duplicated = "model.tabs.duplicated"
            shared     = "exportTab"
            imported   = "openTabImportPopup"
            save       = "saveTab"
        >
        </tabs-with-menu>

        <!-- sidebar: DISABLED -->
        <main ng-if="isSidebarDisabled" class='sidebar--disabled'>

            <metrics-funnel-breadcrumb
                ng-if                  = "model.tabs.selected.funnel"
                user                   = "model.user"
                export-promise-tracker = "exportPromiseTracker"
                model                  = "model.tabs.selected.funnel"
                export                 = "export"
                on-reset               = "onReset()"
                refresh                = "refresh"
                options                = "model.experiments"
                panel-toggle           = "model.tabs.panel"
                sidebar-toggle         = "sidebarModel.toggle"
                experiments            = "model.experiments"
            ></metrics-funnel-breadcrumb>

            <metrics-funnel-node-view
                user          = "model.user"
                model         = "model.tabs.selected.funnel"
                tab-name      = "model.tabs.selected.name"
                export-setter = "exportSetter"
                organization  = "model.organization"
                experiments   = "model.experiments"
            ></metrics-funnel-node-view>
        </main>

        <!-- sidebar: ENABLED -->
        <main ng-if="!isSidebarDisabled && sidebarModel" class='sidebar--enabled'>

            <div class="metrics-funnel-breadcrumb-actions-panel" ng-if="actionsPanelModel.items.length > 0">
                <div class="left-panel">
                    <div class="sidebar-header-close" ng-class="{'closed': !sidebarModel.toggle.isActive}" ng-click="toggleSidebar($event)">
                        <div class="sidebar-toggle-icon"><i class="icon-left-open"></i></div>
                    </div>
                </div>
                <actions-panel model="actionsPanelModel"></actions-panel>
            </div>

            <main class="main-body">
                <sidebar model="sidebarModel"></sidebar>
                <main ng-if="model.tabs.selected.funnel">
                    <metrics-funnel-breadcrumb
                        export-promise-tracker = "exportPromiseTracker"
                        user                   = "model.user"
                        model                  = "model.tabs.selected.funnel"
                        export                 = "export"
                        on-reset               = "onReset()"
                        refresh                = "refresh"
                        options                = "model.experiments"
                        panel-toggle           = "model.tabs.panel"
                        sidebar-toggle         = "sidebarModel.toggle"
                        experiments            = "model.experiments"
                    ></metrics-funnel-breadcrumb>
                    <metrics-funnel-node-view
                        user          = "model.user"
                        model         = "model.tabs.selected.funnel"
                        tab-name      = "model.tabs.selected.name"
                        export-setter = "exportSetter"
                        organization  = "model.organization"
                        experiments   = "model.experiments"
                    ></metrics-funnel-node-view>
                </main>
            </main>
        </main>
    </section>
    """
    link: (scope, element) ->
        scope.exportPromiseTracker = promiseTracker()
        scope.exportButtonText = 'export'
        $element = $(element)

        scope.exportTab = undefined
        scope.openTabImportPopup = undefined

        scope.refresh = () ->
            scope.model.tabs.selected.refresh()

        scope.saveTab = -> scope.model.tabs.saveState()

        scope.removeTab = (tabId) ->
            scope.model.tabs.removeTab(tabId) if window.confirm("""
                Are you sure you want to delete the View - "#{scope.model.tabs.selected.name}" ?\nThis action cannot be un-done.
            """)

        updatePanel = (isOpen) ->
            isOpen = do ->
                return isOpen if isOpen is not undefined
                return scope.model?.tabs?.panel?.state?.isOpen
            return if isOpen is undefined

            $headerActionsPanel = $element.find('.collapsed-header-actions')
            $actionsSelectionPanel = $element.find('.actions-selection-panel')

            if not isOpen
                $headerActionsPanel.addClass('show')
                $actionsSelectionPanel.addClass('hide')
            else
                $headerActionsPanel.removeClass('show')
                $actionsSelectionPanel.removeClass('hide')

        ###* @argument {null | {[x: string]: unknown}} [panelState=null] ###
        updatePanelView = (panelState = null)->
            panelState ?= scope.model?.tabs.panel?.state
            return if panelState is null or panelState is undefined
            updatePanel(panelState.isOpen)
            return

        ## After first loading
        $timeout (->
            updatePanelView()
        ), 1000

        transitionsTimeoutCancelFn = null
        initTransition = ->
            transitionsTimeoutCancelFn?()
            headerElement = $element.find('.metrics-funnel-node')
            headerElement.addClass('transitions')
            headerTransitionTimeout = $timeout((-> headerElement.removeClass('transitions')), 1000)
            headerActionsPanel = $element.find('.collapsed-header-actions')
            headerActionsPanel.addClass('transitions')
            headerActionsTimeout = $timeout((-> headerActionsPanel.removeClass('transitions')), 500)
            transitionsTimeoutCancelFn = ->
                $timeout.cancel(headerTransitionTimeout)
                $timeout.cancel(headerActionsTimeout)
            return

        resizeObserver = null
        initResizeObserver = ->
            headerElement = element.find('.metrics-funnel-breadcrumb').get(0)
            return if not headerElement
            resizeObserver?.disconnect()
            resizeObserver = new ResizeObserver (-> updatePanelView())
            resizeObserver.observe(headerElement)
            return

        scope.onReset = ->
            scope.model.tabs.selected.reset()

        # FIXME: remove this thing...
        scope.exportSetter = (exportFunc) ->
            scope.export = exportFunc

        scope.$watch 'model.tabs.panel.state', (curr, prev) ->
            initTransition() if prev
            updatePanelView(curr)
            scope.model?.tabs.saveState()
            return

        scope.toggleSidebar = (event) ->
            isActive = scope.sidebarModel.toggle.isActive
            return scope.sidebarModel.toggle.open(event, 'properties') if not isActive
            return scope.sidebarModel.toggle.close(event)

        createSidebarModel = ->
            scope.sidebarModel = do ->
                return undefined if scope.isSidebarDisabled
                { properties, metrics, nodes, node } = scope.model.tabs?.selected?.funnel ? { metrics: undefined, nodes: undefined, properties: undefined, node: undefined }
                return if not metrics or not nodes
                { selected: selectedMetrics, available: availableMetrics } = metrics

                return new SidebarModel({
                    options: { hideTabs: true },
                    properties: {
                        selected: node.property,
                        available: node?.properties ? properties,
                        options: {
                            multipleSelection: true
                        }
                        selectProperty: (property) ->
                            scope.model.tabs.selected.funnel.setProperty(property)
                    },
                    metrics: {
                        selected: selectedMetrics.map((m) -> m.field)
                        available: availableMetrics
                        options: { addAllMetrics: false, hideCategories: not scope.model.experiments.metricsCategorization }
                        selectMetrics: (metrics) ->
                            newMetrics = metrics.map((x) -> x.field)
                            scope.model.tabs?.selected?.funnel.selectMetrics(newMetrics)
                    },
                    toggle: do ->
                        return scope.sidebarModel.toggle if scope.sidebarModel?.toggle
                        return undefined
                })

        updateSidebarModel = ->
            return undefined if scope.isSidebarDisabled
            return createSidebarModel() if not scope.sidebarModel
            { properties, metrics, nodes, node } = scope.model.tabs?.selected?.funnel ? { metrics: undefined, nodes: undefined, properties: undefined, node: undefined }
            return if not metrics or not nodes
            { selected: selectedMetrics, available: availableMetrics } = metrics

            propertiesToCompare = node?.properties ? properties
            isSamePropertiesArguments = _.isEqual(scope.sidebarModel.properties.available, propertiesToCompare) and _.isEqual(scope.sidebarModel.properties.selected, node.property)
            if not isSamePropertiesArguments
                scope.sidebarModel.properties = {
                    selected: node.property,
                    available: node?.properties ? properties,
                    options: {
                        multipleSelection: true
                    }
                    selectProperty: (property) ->
                        scope.model.tabs.selected.funnel.setProperty(property)
                }

            isSameMetricsArguments = _.isEqual(scope.sidebarModel.metrics.selected, selectedMetrics.map((m) -> m.field)) and _.isEqual(scope.sidebarModel.metrics.available, availableMetrics)
            if not isSameMetricsArguments
                scope.sidebarModel.metrics = {
                    selected: selectedMetrics.map((m) -> m.field)
                    available: availableMetrics
                    options: { addAllMetrics: false, hideCategories: not scope.model.experiments.metricsCategorization }
                    selectMetrics: (metrics) ->
                        newMetrics = metrics.map((x) -> x.field)
                        scope.model.tabs?.selected?.funnel.selectMetrics(newMetrics)
                }


        createActionsPanelModel = ->
            scope.actionsPanelModel = do ->
                scope.isSidebarDisabled = not scope.model.experiments.sidebar
                return undefined if scope.isSidebarDisabled

                actionsPanelModel = []
                actionsPanelModel.push(new ActionsPanelButtonItem({
                    label: 'Rows By',
                    selected: do ->
                        return scope.model.tabs.selected.funnel?.node.property.map((p) -> p.label).join(', ')
                    cssClass: 'sidebar-toggle'
                    icon:
                        type: 'icon-down-open'
                    isActive: -> scope.sidebarModel?.toggle.isActive and scope.sidebarModel.toggle.tab is 'properties'
                    onClick: ($event) ->
                        scope.sidebarModel.toggle.toggle($event, 'properties')
                }))

                actionsPanelModel.push(new ActionsPanelSimpleItem({
                    label: 'Edit Metrics',
                    icon:
                        type: 'icon-flow-cascade'
                    cssClass: 'sidebar-toggle'
                    isActive: -> scope.sidebarModel?.toggle.isActive and scope.sidebarModel.toggle.tab is 'metrics'
                    onClick: ($event) ->
                        scope.sidebarModel.toggle.toggle($event, 'metrics')
                }))

                actionsPanelModel.push(new ActionsPanelButtonItem({
                    secondary: true,
                    label: 'Export',
                    isLoading: () -> scope.exportPromiseTracker?.active()
                    icon:
                        type: 'icon-export'
                    onClick: () ->
                        scope.exportPromiseTracker.cancel()
                        scope.exportPromiseTracker.addPromise(do ->
                            Analytics.track(Analytics.EVENTS.USER_EXPORTED_PAGE_METRICS)
                            return scope.export()
                        )
                }))

                return new ActionsPanelModel({items: actionsPanelModel})

            if scope.actionsPanelModel?.items.length > 3
                ## DEBUG purposes - https://42t.sentry.io/issues/6121764949/events/1d4ca946fdc34f6a8c21b112d4c1429d/
                console.log('[metrics-page] Funnel: ', JSON.stringify(scope.model.tabs.selected.funnel))
                Analytics.logError('[metrics-page] Actions panel build error.')

        unWatchTabChanges = ->

        scope.$watch 'model.tabs.selected.id', (id) ->
            unWatchTabChanges()
            return if _.isNil(id)
            initResizeObserver()
            createSidebarModel()

            scope.model.tabs.saveState()
            unWatchTabChanges = scope.$watch 'model.tabs.selected.funnel', ((funnel) ->
                return if not funnel
                updateSidebarModel()
                createActionsPanelModel()
                scope.model.tabs.saveState() if (funnel.nodes.findIndex (n) -> n.id is funnel.node.id) is -1
                return
            ), true
            return

        dnd = scope.fillDragAndDropZoneExternalAPI({
            onError: (error) -> Analytics.track(Analytics.EVENTS.USER_IMPORT_METRICS_TAB_FAILED, {error}),
            onFile: (file) ->
                data = MetricsPageTabsViewModel.ValidateViewConfigFile(file, scope.model.organization)
                scope.model.tabs.createTabFromJSON(data)
                Analytics.track(Analytics.EVENTS.USER_IMPORT_METRICS_TAB, {tab: data})
        })

        scope.exportTab = (...args) -> scope.model.tabs.exportTab(...args)
        scope.openTabImportPopup = ($event) ->
            $event.preventDefault()
            $event.stopImmediatePropagation()
            throw new Error("[grid-page] Drag and Drop zone not initialized") if not dnd
            dnd.openUploadPopup?()
            return true

        scope.$watch 'model.experiments', (experiments) ->
            return if not experiments
            scope.isSidebarDisabled = not experiments.sidebar

        scope.$on '$destroy', -> resizeObserver?.disconnect()
